.view {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    padding-top: 60px;
    box-sizing: border-box;
    background-image: url('https://mdn.alipayobjects.com/yuyan_qk0oxh/afts/img/V-_oS6r-i7wAAAAAAAAAAAAAFl94AQBr');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    box-sizing: border-box;
}
.logo {
    width: 220px;
}
.desc {
    color: rgba(0, 0, 0, 0.45);
    margin: 0 0 30px;
}
.form {
    width: 280px;
    padding-bottom: 100px;
}
.send {
    color: #122c42;
    padding: 0 12px;
    cursor: pointer;
    &:hover {
        color: #ffd13d;
    }
}
.link {
    font-weight: 400;
    cursor: pointer;
    font-size: 14px;
}
.controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 260px;
    padding: 0 10px;
    margin-bottom: 24px;
}
.loginBtn {
    width: 280px;
    font-weight: 500;
}
