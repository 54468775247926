.view {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: -80px;
    min-height: 100vh;
}
.desc {
    margin-bottom: 50px;
    font-size: 16px;
    text-align: center;
    color: #000;
    font-weight: 600;
}
.form {
    width: 420px;
}
.inputNumber {
    width: 100%;
}
.send {
    color: #122c42;
    padding: 0 12px;
    cursor: pointer;
    &:hover {
        color: #ffd13d;
    }
}
.tpic {
    font-size: 14px;
    text-align: center;
    padding: 20px 0;
    color: rgba(0, 0, 0, 0.65);
}
.btns {
    text-align: center;
}
.btn {
    width: 260px;
    font-weight: 600;
}
