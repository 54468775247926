.view {
    height: 100vh;
    box-sizing: border-box;
    overflow: hidden;
}

.layout {
    background: linear-gradient(#ffffff, #f5f5f5 28%);
}

.content {
    text-align: center;
    background-color: transparent;
    padding: 8px 40px;
    overflow-y: auto;
    box-sizing: border-box;
}

.breadcrumb {
    padding-top: 18px;
    margin-bottom: 20px;
}

.header {
    width: auto;
    height: 56px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-block-end: 1px solid rgba(5, 5, 5, 0.06);
    background-color: #fff;
    padding: 0 20px;
}

.logoTitle {
    height: 56px;
    display: flex;
    align-items: center;

    img {
        width: 52px;
        height: 52px;
        margin-right: 10px;
    }

    p {
        font-size: 18px;
        font-weight: 600;
        color: rgba(0, 0, 0, 0.88);
    }
}

.name {
    color: rgba(0, 0, 0, 0.88);
    margin-left: 12px;
    font-weight: 600;
    font-size: 15px;
}

.divider {
    height: 100%;
}

.sider {
    position: relative;
    text-align: left;
    color: #fff;
    background: transparent;
    border: none !important;
}

.collapsed {
    position: absolute;
    inset-block-start: 18px;
    z-index: 101;
    width: 24px;
    height: 24px;
    text-align: center;
    border-radius: 40px;
    inset-inline-end: -11px;
    transition: transform 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: rgba(0, 0, 0, 0.25);
    background-color: #ffffff;
    box-shadow: 0 2px 8px -2px rgba(0, 0, 0, 0.05), 0 1px 4px -1px rgba(25, 15, 15, 0.07), 0 0 1px 0 rgba(0, 0, 0, 0.08);
    font-size: 10px;
}

.rotated {
    transform: rotate(180deg);
}

.menu {
    width: 240px;
    background-color: transparent;
    border: none;
    height: 100%;
}
